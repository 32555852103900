import React from 'react';
import PropTypes from 'prop-types';

import { Layout, SEO } from 'components';

import content from './partners.yml';

const PartnersPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale}>
    <SEO
      title={content.title}
      description={content.description}
      lang={locale}
      keywords={content.keywords}
      location={location}
      image={content.socialImage}
    />

    <h3 className='section-title mt-50 mb-50'>Partners</h3>
  </Layout>
);

PartnersPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default PartnersPage;
